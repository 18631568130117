
.for-file {
  input[type="file"] {
    display: none;
  }

  .label, .label label {
    cursor: pointer;
  }

  label.form-control {
    overflow: hidden;
    line-height: 22px;
  }
}
