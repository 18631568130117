
.widget {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, .1);
  margin-bottom: 23px;
  display: block;

  &.disabled {
	background: #e0e0e0;
	pointer-events: none;
	opacity: 0.6;
  }

  header {
    border-top: 4px solid $color-primary;
    border-radius: 4px 4px 0 0;
    padding: 15px 17px;
    color: #5e6977;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .07);

    h2 {
      font-size: 17px;
      font-weight: 500;
      margin: 0 0 3px;
    }

    p {
      font-size: 13px;
      margin: 0;
    }
  }

  .widget-content {
    padding: 5px 17px;
    font-size: 13px;

    &.w-scroll {
      overflow-y: scroll;
    }
  }

  a {
    color: $color-primary;
  }

  .table {
    margin: 0;

    img {
      border-radius: 4px;
    }
  }

  .link-view-all {
    font-weight: 500;
    color: #5e6977;
  }


  &.margin-bottom-10 {
    margin-bottom: 10px;
  }


  &.widget-support-custom {
    position: relative;

    header {
      border-top: 0;
      background: $color-primary url(../assets/images/bg-support.png) right center no-repeat;
      background-size: contain;
      padding: 25px 17px;
      margin-bottom: 10px;

      h2 {
        color: #fff;
        margin-bottom: 10px;
        font-size: 23px;
        font-weight: 400;
        max-width: 80%;
      }

      p {
        color: #fff;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    .half {
    	width: 50%;
    }

    .floating-button {
      position: absolute;
      right: 15px;
      bottom: 25px;
      display: inline-block;
      text-align: center;
      z-index: 100;
      transition: transform 0.1s ease-in-out;

      &:last-child {
        right: 125px;
      }

      span {
        width: 42px;
        height: 42px;
        display: inline-block;
        border-radius: 50%;
        border: 2px solid #fff;
        background: #3e5f68;
        line-height: 38px;
        color: #fff;
        font-size: 18px;
      }

      p {
        color: #5e6977;
        margin-bottom: 0;
      }

      &:hover {
        text-decoration: none;
        transform: scale(1.1);
      }
    }
  }


  &.quick-action {

    .widget-icon {
      background: $color-primary;
      float: left;
      width: 80px;
      height: 70px;
      line-height: 80px;
      text-align: center;
      border-radius: 4px 0 0 4px;
      color: #fff;
      margin-right: 15px;
    }

    .widget-stat {
      float: right;
      padding: 9px 19px;
      opacity: .5;

      p {
        margin-bottom: 0;
        color: #86939e;
      }

      strong {
        font-size: 23px;
        color: #3e5f68;
        font-weight: 300;
      }
    }

    .widget-content {
      padding: 13px 16px;

      p {
        font-size: 16px;
        margin-bottom: 0;
        color: #3e5f68;
      }

      small {
        font-size: 13px;
        color: #86939e;
      }
    }

    &:hover {
      text-decoration: none;

      .widget-content p {
        color: $color-primary;
      }
    }
  }

  &.dark {
    background: #3e5f68;

    header {
      border: 0;
      background: #3e5f68;

      h2 {
        color: #fff;
      }

      p {
        color: #779da7;
      }
    }

    .widget-content {
      padding: 15px 0;
      color: #fff;
      overflow-y: auto !important;
    }
  }

  &.widget-stats {
    .btn-link {
      color: $color-light;

      .fa-check-square {
        display: none;
      }
    }

    .btn-link.active {
      .fa-check-square {
        display: inline-block;
      }
      .fa-square {
        display: none;
      }
    }
  }

}
