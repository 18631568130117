.fc-day-grid-event{

  .fc-content {
    white-space: pre-line;
    cursor: pointer;
  }

  .fc-time{
    display:none;
  }
}

.event-date-picker {

  &.w-border {
    border: 1px solid $color-muted;
  }

  .input-group-btn {
    button {
      border: 0;

      &:hover {
        background: none;
      }
    }
  }

  input {
    border: 0;
    background: transparent;
    box-shadow: none;
    padding: 0;
    font-weight: 500;
  }

  &:hover  {
    > .input-group-btn button.btn, > input.form-control {
      color: $color-primary;
      cursor: pointer;
    }
  }
}

.event-time-picker {
  margin-top: -16px;
  transition: opacity 0.2s ease-in-out;

  &[disabled] {
    opacity: 0.3;
  }

  .uib-time {
    input {
      background: #fff;
      border: 0;
      padding: 0;
      width: 30px;
      font-weight: 500;
      height: 24px;
      line-height: 24px;
      box-shadow: none;
    }
  }

  .uib-increment, .uib-decrement {
    padding: 0;

    .btn-link {
      padding: 0;
      line-height: 12px;
      font-size: 12px;
    }
  }

  .am-pm .btn-default {
    background: #fff;
    border: 0;
    padding: 0;
    width: 30px;
    font-weight: 500;
    height: 24px;
    line-height: 24px;
    box-shadow: none;
  }


}
