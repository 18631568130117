
.modal-open-login [ng-view] {
  filter: blur(6px);
}

.modal-login{
  .modal-dialog {
    width: 500px;
  }

  .modal-header {
    img {
      margin-top: 13px;
    }
  }
}
