header.twd-header {
  margin-bottom: 23px;

  .header-info {
    background: #ffffff;
    height: 76px;
    padding: 17px;

    .website {
      font-size: 21px;
      color: $color-light;
      font-weight: 300;
      line-height: 42px;

      a {
        color: $color-secondary;
        font-weight: 500;

        i {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        &:focus, &:active {
          text-decoration: none;
        }

        &:hover {
          text-decoration: none;

          i {
            opacity: 1;
          }
        }
      }
    }

    .notifications {
      float: right;
      margin-right: 35px;
      line-height: 42px;

      a {
        font-size: 20px;
        line-height: 46px;

        &:focus, &:hover, &:active {
          outline: 0;
        }
      }

      span {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url(../assets/images/icon-bell.png);
      }

      span[title="0"] {
        &:after {
          display: none;
        }
      }

      span:after {
        content: attr(title);
        position: absolute;
        height: 19px;
        top: -8px;
        right: -8px;
        font-weight: 500;
        width: 19px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        border-radius: 50%;
        color: $color-primary;
        background: url(../assets/images/icon-notifications.png);

        //background: #fff;
        //border: 1px solid #e92b2c;
      }
    }

    .account {
      font-weight: 500;
      float: right;

      img {
        border-radius: 50%;
        float: right;
        margin-right: 10px;
        max-height: 42px;
      }

      .dropdown-toggle {
        display: inline-block;
      }

      .user-info {
        float: right;
        text-align: left;

        p {
          font-size: 16px;
          color: $color-secondary;
          margin-bottom: -5px;
          margin-top: 4px;
        }

        small {
          font-size: 10px;
          color: $color-light;
        }
      }

      .caret {
        float: right;
        margin-left: 10px;
        margin-top: 22px;
        color: $color-light;
      }
    }
  }

  nav {
    background: $color-primary;

    ul {
      margin: 0;
      text-align: center;
    }

    li {
      display: inline-block;
      list-style-type: none;
    }

    a {
      border-top: 3px solid transparent;
      font-size: 15px;
      color: #f29e9e;
      display: inline-block;
      line-height: 57px;
      margin: 0 60px;
    }

    .selected a, a:hover {
      color: #fff;
      text-decoration: none;
      border-color: #cfcfcf;
    }
  }
}


.popover-notifications {
  min-width: 350px;

  .popover-content {
    padding: 0;
  }

  table {
    margin-bottom: 0;

    td {
      padding: 0 15px !important;
    }
  }
}

