/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
 */
$navbar-inverse-link-color: #5AADBB;
$icon-font-path: "../fonts/";

/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');


// VARIABLES
$color-primary: #d41f36;
$color-secondary: #4b6069;
$color-muted: #f0efef;

$color-light: #97a6ba;

$color_1: white;
$color_2: #595959;
$color_3: #999;
$color_4: #777;
$background_color_1: #f5f5f5;
$border_color_1: #d41f36;
$border_color_2: transparent transparent transparent #d41f36;

// GENERAL APP STYLES

body {
  background: #ececec;
  font-family: 'Roboto', sans-serif;
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary {
  background: $color-secondary;
  border-color: $color-secondary;
  color: #fff !important;

  &:hover {}
}

.ta-editor {
  min-height: 75px !important;
  height: auto;
  overflow: auto;
  font-family: inherit;
  font-size: 100%;

  textarea {
    display: none;
  }
}

.ta-scroll-window,
.ta-scroll-window>.ta-bind {
  min-height: 75px !important;
}

.label label {
  color: #fff;
  font-size: 100%;
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.label.pull-right {
  margin-top: 4px;
  display: inline-block;
  line-height: 12px;
}

.margin-right-15 {
  margin-right: 15px;
}

.btn-link {
  color: #5e6977;
  font-weight: 500;

  &.selected {
    color: $color-primary;
  }

  &:hover {
    text-decoration: none;
  }
}

.form-group {
  &.for-featured-image {
    input[type="file"] {
      display: inline;
      width: 50px;
      opacity: 0;
    }
  }
}

.table {
  th {
    color: #97a6ba;
    font-weight: 400;
    padding: 0 !important;
    line-height: 47px !important;
    border-color: #edf1f5 !important;
    border-bottom-width: 1px !important;
  }

  td {
    color: #5e6977;
    padding: 0 !important;
    line-height: 47px !important;
    border-color: #edf1f5 !important;
  }

  .column-actions>* {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  tr:hover .column-actions>* {
    opacity: 1;
  }

  img {
    border-radius: 4px;
  }

  th[st-sort] {

    .fa-sort-asc,
    .fa-sort-desc {
      display: none;
    }

    &.st-sort-ascent {
      .fa-sort {
        display: none;
      }

      .fa-sort-asc {
        display: inline-block;
      }
    }

    &.st-sort-descent {
      .fa-sort {
        display: none;
      }

      .fa-sort-desc {
        display: inline-block;
      }
    }
  }
}

form {

  label {
    font-size: 14px;
    font-weight: 400;
    color: #86939e;
  }

  .checkbox label {
    color: #333;
  }

}

.glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-chevron-left:before {
  content: "\f053";
}

.glyphicon-chevron-right:before {
  content: "\f054";
}

.glyphicon-chevron-up:before {
  content: "\f077";
}

.glyphicon-chevron-down:before {
  content: "\f078";
}

.page {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, .1);
  margin-bottom: 23px;
  display: block;

  header {
    border-top: 4px solid $color-primary;
    border-radius: 4px 4px 0 0;
    padding: 15px 17px;
    color: #5e6977;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .07);

    h1 {
      font-size: 17px;
      font-weight: 500;
      margin: 0 0 3px;
    }

    p {
      font-size: 13px;
      margin: 0;
    }
  }

  .page-status {
    border-top: 1px solid #e8ecf0;
    background: #f2f2f2;
    line-height: 30px;
    padding: 10px 17px;
    color: #5e6977;
  }

  .page-content {
    padding: 5px 17px;
    font-size: 13px;
    overflow-y: scroll;
  }

  a {
    color: #5e6977;

    &.selected,
    &:hover {
      color: $color-primary;
      text-decoration: none;
    }
  }

}


.modal-dialog {

  .modal-header {
    border-top: 4px solid $color-primary;
    border-radius: 4px 4px 0 0;
    padding: 15px 17px;
    color: #5e6977;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .07);

    h3 {
      font-size: 17px;
      font-weight: 500;
      margin: 0 0 3px;
    }

    p {
      font-size: 13px;
      margin: 0;
    }
  }

  .page-content {
    padding: 5px 17px;
    font-size: 13px;
    overflow-y: scroll;
  }

  a {
    color: #5e6977;

    &.selected,
    &:hover {
      color: $color-primary;
      text-decoration: none;
    }
  }

  .modal-footer {
    border-color: #e1e8ee;
    padding: 0;

    .btn-link {
      line-height: 35px;
    }

    .row .col-sm-6:first-child {
      border-right: 1px solid #e1e8ee;
    }
  }

}


.red td {
  background: #d41f36;
  color: white;

  a {
    background: #d41f36;
    color: white;

    &:hover,
    .fa-stack-2x {
      color: #2b2b2b;
    }
  }
}

.radio-col {
  width: 100%;

  span {
    width: 100%;
    border: 2px solid #f5f5f5;
    border-radius: 12px;
    padding: 60px 20px 30px;
    text-align: center;
    display: block;
    background: #f5f5f5;
  }

  h4 {
    margin-top: 0;
    font-size: 22px;
    text-transform: uppercase;
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;
  }

  i {
    font-size: 7em;
  }

  #alert_type {
    display: none;

    &:checked {
      &+span {
        background: #d41f36;
        border-color: $border_color_1;
        color: $color_1 !important;

        i {
          color: $color_1;
        }
      }
    }
  }

  #alert_delivery {
    display: none;

    &:checked {
      &+span {
        background: #d41f36;
        border-color: $border_color_1;
        color: $color_1 !important;

        i {
          color: $color_1;
        }
      }
    }
  }
}

.modal-dialog {
  .modal-footer {
    .row {
      .col-sm-4 {
        &:nth-child(2) {
          border-right: 1px solid #e1e8ee;
          border-left: 1px solid #e1e8ee;
        }
      }
    }
  }
}

.bs-wizard {
  border-bottom: solid 1px #e0e0e0;
  padding: 0 0 10px 0;
  margin: 40px 0 60px;

  >.bs-wizard-step {
    padding: 0;
    position: relative;

    .bs-wizard-stepnum {
      color: $color_2;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .bs-wizard-info {
      color: $color_3;
      font-size: 14px;
    }

    >.bs-wizard-dot {
      position: absolute;
      width: 30px;
      height: 30px;
      display: block;
      background: #d41f36;
      top: 0px;
      left: 50%;
      margin: 10px 0;
      border-radius: 50%;
      text-align: center;
      padding: 5px;
      font-weight: bold;
      color: $color_1;
    }

    >.progress {
      position: relative;
      border-radius: 0px;
      height: 8px;
      box-shadow: none;
      margin: 20px 0;

      >.progress-bar {
        width: 0px;
        box-shadow: none;
        background: #d41f36;
        transition: 0s;
      }
    }

    &:first-child.active {
      >.progress {
        >.progress-bar {
          width: 0%;
        }
      }
    }

    &:last-child.active {
      >.progress {
        >.progress-bar {
          width: 100%;
        }
      }
    }

    &:first-child {
      >.progress {
        left: 50%;
        width: 50%;
      }
    }

    &:last-child {
      >.progress {
        width: 50%;
      }
    }
  }

  >.bs-wizard-step.complete {
    >.progress {
      >.progress-bar {
        width: 100%;
      }
    }
  }

  >.bs-wizard-step.active {
    >.progress {
      >.progress-bar {
        width: 50%;
      }
    }
  }

  >.bs-wizard-step.disabled {
    >.bs-wizard-dot {
      background-color: $background_color_1;
      color: $color_4;

      &:after {
        opacity: 0;
      }
    }

    a.bs-wizard-dot {
      pointer-events: none;
    }
  }
}

.span-title {
  padding: 15px 8px 15px 15px;
  margin: 0;
  background: #d41f36;
  display: inline-block;
  color: $color_1;
  position: relative;
  min-width: 120px;
  font-size: 15px;

  &:before {
    content: '';
    border-style: solid;
    border-width: 25px 0px 25px 15px;
    border-color: $border_color_2;
    position: absolute;
    right: -15px;
    top: 0;
  }
}

.radio-checkbox {
  position: absolute;
  top: 15px;
  left: 50%;
}

.span-content {
  display: inline-block;
  padding: 15px 30px;
  font-size: 15px;
}

.span-type {
  text-transform: uppercase;
}

.panel-body-dark {
  padding: 20px;
  background: #2b2b2b;
  color: $color_1;
  border-radius: 12px;
  font-size: 15px;
}

.btn-alert {
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px;
}

/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "events/events.scss";
@import "main/main.scss";
@import "meeting-repository/meeting-repository.scss";
@import "events-calendar/events.scss";
@import "support/support.scss";
@import "components/notifications/notifications.scss";
@import "components/twdHeader/twdHeader.scss";
@import "components/user/user.scss";
// endinjector
#subs_table{
  margin-top: 20px;
  margin-bottom: 20px;
}

#subs_table tbody tr td:nth-child(10n),
#subs_table tbody tr td:nth-child(8n),
#subs_table thead th:nth-child(10n),
#subs_table thead th:nth-child(8n){
  display: none;
}
#subs_table thead th:after,
#subs_table thead th:before{
  bottom: 0
}

#subs_table thead th{
  color: #97a6ba;
  font-weight: 400;
  padding: 0 !important;
  line-height: 47px !important;
  border-color: #edf1f5 !important;
  border-bottom-width: 1px !important;
}
#subs_table_wrapper #subs_table_paginate,
#subs_table_wrapper #dataTables_info,
#subs_table_wrapper #subs_table_filter,
#subs_table_wrapper .dt-buttons{
  display: inline;
}
#subs_table_wrapper #subs_table_paginate,
#subs_table_wrapper #subs_table_filter{
  float:right
}
#subs_table_wrapper #subs_table_filter input{
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}
#subs_table_wrapper #subs_table_paginate{
  margin-bottom: 20px;
  border-radius: 4px;
}
#subs_table_wrapper #subs_table_paginate .ellipsis,
#subs_table_wrapper #subs_table_paginate .paginate_button {
  padding: 5px;
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.428571429;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
#subs_table_wrapper #subs_table_paginate a.current{
  background-color: #337ab7;
  border-color: #337ab7;
  color: #fff
}

#subs_table_wrapper .table-stripped{
  padding: 40px;
  display: block;
}