
.popover-notifications {
  .popover-content {
    max-height: 200px;
    overflow-y: scroll;
  }
}

.notifications-list {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    border-bottom: 1px solid $color-muted;
    padding: 5px 10px;
    cursor: pointer;

    p {
      margin: 0;
    }

    &.unread {
      background-color: lighten($color-light, 20%);
    }

    &:hover {
      background-color: $color-muted;
    }
  }

  &.in-widget {
    margin: 0 -17px;

    li {
      padding: 10px 17px;
    }
  }

}
